import { createMap, createGoogleMap } from '../../utilities/maps/maps.js';
import { handleGeocode } from '../../utilities/maps/geocode.js';
import i18n from '../../utilities/i18n.js';

// Foundation.Abide.defaults.patterns['postcode'] = /^ *[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2} *$/i;

export async function doMapPage() {
	const { Map } = await google.maps.importLibrary("maps");
	const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
	const { Place } = await google.maps.importLibrary("places");

	const PAGINATION_SPEED = 200;
	const MAP_PADDING = 40;
	const SINGLE_SITE_ZOOM = 13;
	const MARKER_DEFAULT = '/assets/img/map-pin-car-park-default.png';
	const MARKER_HIGHLIGHTED = '/assets/img/map-pin-car-park-highlight.png';
	const MARKER_HOVER = '/assets/img/map-pin-car-park-hover.png';
	const MARKER_USER = '/assets/img/map-pin-user-defined.png';
	const FAVOUR_LOCATION_NAMES = false;
	const USE_FUSE = false;

	let sites = [];
	let locations = $('#locations').inlineJson();
	let categories = $('#categories').inlineJson();
	let locationsCollection = $('#locationsCollection').inlineJson();
	let dispensations = $('#dispensations').inlineJson();
	let hash = null;
	let waitForTransition = true;
	let hideLocationsForCategory = $('#hideLocationsForCategory').val() == 1;
	let idlecallback = null;
	let idle = false;

	// Create our array of sites
	$.each(locations, function (i, location) {
		switch (location.locationType) {
			case 'sites':
				if (sites[location.locationId] == undefined) {
					sites[location.locationId] = getSite(location, { locations: [{ index: i, type: 'sites', 'id': location.locationId }] });
				} else {
					sites[location.locationId].locations.push({ index: i, type: 'sites', 'id': location.locationId });
				}
				break;
			case 'groups':
				// Convert to proper array
				location.sites = Object.values(location.sites);
				$.each(location.sites, function (s, site) {
					if (sites[site.siteId] == undefined) {
						sites[site.siteId] = getSite(site, { locations: [{ index: i, type: 'groups', 'id': location.locationId }] });
					} else {
						sites[site.siteId].locations.push({ index: i, type: 'groups', 'id': location.locationId });
					}
				});
				break;
		}
	});

	// Convert to proper array
	sites = Object.values(sites);

	// Set some more properties that will not change
	$.each(locations, function (i, location) {
		let locationType = location.locationType.match(/^(\w+?)(s*)$/);
		location.type = locationType[1];
		switch (location.locationType) {
			case 'globals':
				location.count = sites.length;
				break;
			case 'groups':
				location.count = Object.keys(location.sites).length;
				break;
			case 'sites':
				location.count = 1;
				break;
		}
		location.markers = [];
	});

	$.each(categories, function (i, category) {
		let count = 0;
		let unique = [];
		let hasGlobal = false;
		let hasDispensation = false;
		if (category.locations) {
			$.each(category.locations, function (c, cloc) {
				if (cloc.type == 'dispensations') {
					hasDispensation = true;
					return;
				}
				$.each(locations, function (l, location) {
					if ((cloc.type == location.locationType) && (cloc.id == location.locationId)) {
						switch (location.locationType) {
							case 'globals':
								hasGlobal = true;
								break;
							case 'groups':
								$.each(location.sites, function (s, site) { unique[site.siteId] = 1; });
								break;
							case 'sites':
								unique[location.locationId] = 1;
								break;
						}
						return false;
					}
				});
			});
			if (hasGlobal) {
				count = sites.length;
			} else {
				count = Object.keys(unique).length;
			}

			if (hasDispensation) {
				count = count + 1;
			}
		}
		category = $.extend(
			category,
			{
				locationType: 'categories',
				type: 'category',
				locationId: category.id,
				count: count,
			}
		);
		locations.push(category);
	});

	$.each(locationsCollection, function (i, locationCollection) {
		let count = 0;
		let unique = [];
		let hasGlobal = false;
		if (locationCollection.locations) {
			$.each(locationCollection.locations, function (c, cloc) {
				$.each(locations, function (l, location) {
					if ((cloc.type == location.locationType) && (cloc.id == location.locationId)) {
						switch (location.locationType) {
							case 'globals':
								hasGlobal = true;
								break;
							case 'groups':
								$.each(location.sites, function (s, site) { unique[site.siteId] = 1; });
								break;
							case 'sites':
								unique[location.locationId] = 1;
								break;
						}
						return false;
					}
				});
			});
			if (hasGlobal) {
				count = sites.length;
			} else {
				count = Object.keys(unique).length;
			}
		}
		locationCollection = $.extend(
			locationCollection,
			{
				locationType: 'locationsCollection',
				type: 'locationCollection',
				locationId: locationCollection.id,
				count: count,
			}
		);
		locations.push(locationCollection);
	});

	$.each(dispensations, function(d, dispensation) {
		dispensation = $.extend(
			dispensation,
			{
				locationType : 'dispensations',
				type : 'dispensation',
				locationId : dispensation.id,
				count: 0,
			}
		);
		locations.push(dispensation);
	});

	if (USE_FUSE) {

		// Create search index
		const fuseLocations = [...locations];
		const fuse = new Fuse(
			fuseLocations,
			{
				includeScore: true,
				minMatchCharLength: 2,
				ignoreLocation: true,
				//location: 0,
				//distance: 100,
				//threshold: 0.6,
				includeMatches: true,
				keys: [
					{ 'name': 'name', weight: 100 },
					{ 'name': 'description', weight: 1 },
					{ 'name': 'district', weight: 20 },
					{ 'name': 'city', weight: 30 },
					{ 'name': 'county', weight: 40 },
					{ 'name': 'postcode', weight: 40 },
					{ 'name': 'sites.name', weight: 5 }, // Would like to be larger but cornwall's massive groups mess it up
				]
			}
		);
	}

	locations.setDistance = function (lat, lng) {
		let latLng = lat === false ? false : new google.maps.LatLng(lat, lng);
		$.each(this, function (i, location) {
			delete location.sort;
			if (latLng === false) {
				delete location.distance;
			} else {
				switch (location.locationType) {
					case 'sites':
						location.distance = haversine(latLng, new google.maps.LatLng(location.lat, location.lng));
						break;
					case 'groups':
						let min = 999999;
						$.each(location.sites, function (s, site) {
							let distance = haversine(latLng, new google.maps.LatLng(site.lat, site.lng));
							if (distance < min) {
								min = distance;
							}
						});
						location.distance = min;
						break;
					default:
						delete location.distance;
				}
			}
		});
	};

	locations.search = function (search = '', lat = null, lng = null, bounds = null) {
		search = $.trim(search.toLowerCase()).replace(/[\/\s]+/, ' ');
		if (search.length) {
			let restrict = null;
			let words = search.split(' ');
			search = words.join(' ');

			if (FAVOUR_LOCATION_NAMES) {
				$.each(this, function (i, location) {
					let items = [location];
					if (location.locationType == 'groups') {
						$.each(location.sites, function (s, site) {
							items.push(site);
						});
					}
					$.each(items, function (n, item) {
						let name = $.trim(item.name.toLowerCase()).replace(/[\/\s]+/, ' ');
						let parts = name.split(' ');
						if (words.every((v, k) => v === parts[k])) {
							if (item.lat && item.lng) {
								restrict = item.lat + ',' + item.lng;
							}
						}
					});
				});
			}

			if (lat == null) {
				$.get(
					'/applicant/geocode',
					{ search: search, restrict: restrict },
					(response) => {
						if (response.error) {
							lat = false;
							lng = false;
						} else {
							lat = response.location.lat;
							lng = response.location.lng;
							bounds = new google.maps.LatLngBounds(
								{ lat: response.viewport.southwest.lat, lng: response.viewport.southwest.lng },
								{ lat: response.viewport.northeast.lat, lng: response.viewport.northeast.lng },
							);
						}
						locations.search(search, lat, lng, bounds)
					},
					'json'
				);
				return;
			}

			locations.setDistance(lat, lng);

			// Reset sort index
			$.each(this, function (i, location) {
				location.sort = (location.distance ? location.distance : 999999);
			});

			if (USE_FUSE) {

				// Perform search using Fuse
				const results = fuse.search(search);

				// Set sort index and cehck for exact match
				$.each(results, function (index, result) {
					let location = fuseLocations[result.refIndex];
					location.sort = -(results.length - index);

					// Check for exact match
					checkForExactMatch(location, words)
				});
			} else {

				$.each(locations, function (i, location) {
					// Check for exact match
					checkForExactMatch(location, words)
				});
			}

			// No search
		} else {
			locations.setDistance(mapPageMap.getBounds().getCenter().lat(), mapPageMap.getBounds().getCenter().lng());
		}

		locations.sortItems();
		locations.draw();

		idlecallback = $.noop; // Don't try to update the results after moving the map

		if (search.length && (lat !== false)) {
			mapPageMap.setCenter(new google.maps.LatLng(lat, lng));
			if (bounds != null) {
				if (typeof bounds == 'string') {
					let parts = bounds.split('|');
					bounds = new google.maps.LatLngBounds(
						{ lat: Number(parts[2]), lng: Number(parts[3]) },
						{ lat: Number(parts[0]), lng: Number(parts[1]) },
					);
				}
				mapPageMap.fitBounds(bounds, MAP_PADDING);
			}
		}
	};

	locations.getHash = function () {
		let text = '';
		$.each(this, function (i, location) {
			text = text + location.locationType + location.locationId;
		});
		return text.hashCode();
	}

	locations.draw = function () {
		let max = getPageLength();
		let $pages = $('<div>');
		let count = 0;
		let page = 1;
		let $page = $('#pageTpl').clone();
		let layout = $('#items .page').length && $('#items .page').hasClass('layout-grid') ? 'layout-grid' : $('#layout').val();
		$page = $(untokenise($page.html(), { page: page }));
		let $wrapper = $page;
		let units = distanceMeasurementUnit == i18n.__('imperial' ? 'distance.m' : 'distance.km');
		$.each(this, function (i, location) {
			let itemTemplate = '#itemTpl';
			let object = $.extend({}, location);

			if (object.locationType == 'sites' || object.locationType == 'globals' || object.locationType == 'groups') {
				let locationExistsInLocationCollection = false;

				locationsCollection.forEach(function (locationCollection) {
					locationCollection.locations.forEach(function (location) {
						if (location.type == object.locationType && location.id == object.locationId) {
							locationExistsInLocationCollection = true;
							return;
						}
					});
				});
				if (locationExistsInLocationCollection) {
					return;
				}
			}

			// object.ls = location.count == 1 ? '' : 's';
			// object.ts = location.tariffs == 1 ? '' : 's';
			if (!object.buttonText || object.buttonText.length == 0) {
				object.buttonText = i18n.__('buttons.view.tariffs');
			}

			let $icon;

			// if a group contains only 1 location use single site icon
			if (object.locationType == 'groups' && object.count == 1) {
				$icon = $('#sitesIconTpl').clone();
			} else {
				$icon = $('#' + object.locationType + 'IconTpl').clone();
			}

			object.icon = $icon.html();
			switch (object.locationType) {
				case 'sites':
					object.location = i18n.__('portal.map.locations.single');
					break;
				case 'groups':
					if (object.count == 1) {
						object.location = i18n.__('portal.map.locations.single');
					} else {
						object.location = i18n.__('portal.map.locations.multiple');
					}
					break;
				case 'categories':
					itemTemplate = '#categoryTpl';
					object.items = '';
					object.location = i18n.__n('portal.map.locations.n', object.count);
					let $catitems = $('<div>');
					$.each(object.locations, function (c, cloc) {
						let $ctpl = $('#categoryItemTpl').clone();

						if (cloc.type == 'dispensations') {
							let loc = { id: 0, name: 'SELECT LOCATION', locationType: 'dispensations', locationId: -1, type: 'dispensation', categoryId: cloc.id }
							loc.buttonText = i18n.__('buttons.view.tariffs');
							loc.categoryId = object.locationId;
							let $icon = $('#' + cloc.type + 'IconTpl').clone();
							loc.icon = $icon.html();
							object.items = untokenise($ctpl.html(), loc)
							$catitems.append(untokenise($ctpl.html(), loc));
							return;
						}

						$.each(locations, function (i, location) {
							if ((cloc.type == location.locationType) && (cloc.id == location.locationId)) {
								let loc = $.extend({}, location);
								if (!loc.buttonText || loc.buttonText.length == 0) {
									loc.buttonText = i18n.__('buttons.view.tariffs');
								}
								loc.categoryId = object.locationId;
								let $icon = $('#' + cloc.type + 'IconTpl').clone();
								loc.icon = $icon.html();
								object.items += untokenise($ctpl.html(), loc)
								$catitems.append(untokenise($ctpl.html(), loc));
								return false;
							}
						});
					});
					object.items = $catitems.html();
					break;
				case 'locationsCollection':
					itemTemplate = '#locationTpl';
					object.items = '';
					object.location = i18n.__n('portal.map.locations.n', object.count);
					let $locCollitems = $('<div>');
					$.each(object.locations, function (c, cloc) {
						let $ctpl = $('#locationItemTpl').clone();
						$.each(locations, function (i, location) {
							if ((cloc.type == location.locationType) && (cloc.id == location.locationId)) {
								let loc = $.extend({}, location);
								if (!loc.buttonText || loc.buttonText.length == 0) {
									loc.buttonText = i18n.__('buttons.view.tariffs');
								}
								loc.locationCollectionId = object.locationId;
								let $icon = $('#' + cloc.type + 'IconTpl').clone();
								loc.icon = $icon.html();
								object.items += untokenise($ctpl.html(), loc)
								$locCollitems.append(untokenise($ctpl.html(), loc));
								return false;
							}
						});
					});
					object.items = $locCollitems.html();
					break;
				case 'globals':
					object.location = i18n.__('portal.map.locations.all');
					break;
				case 'dispensations':
					itemTemplate = '#dispensationTpl';
					object.buttonText = i18n.__('buttons.view.tariffs');
					break;

			}
			switch (true) {
				case (location.distance == undefined):
					object.miles = '';
					break;
				case (location.distance < 0.1):
					object.miles = i18n.__('portal.map.locations.current');
					break;
				case (location.distance > 500):
					object.miles = '';
					break;
				case (location.distance > 50):
					object.miles = Math.round(location.distance) + units;
					break;
				default:
					object.miles = location.distance.toFixed(1) + units;
			}
			let $tpl = $(itemTemplate).clone();

			$wrapper.append(untokenise($tpl.html(), object));

			// If we are showing a category and want to hide other items create a new container for all following items
			if (hideLocationsForCategory && object.sort == -1000) {
				$page.append($('#hiddenItemsTpl').children('li.l').clone().attr('id', 'toggle-hidden-items'));
				$wrapper = $('#hiddenItemsTpl').children('ul.c').clone().attr('id', 'hidden-items').hide();
				const $li = $('<li></li>');
				$li.append($wrapper);
				$page.append($li);
			}

			let uid = '#' + location.locationType + '_' + location.locationId;
			let $item = $page.find(uid);
			switch (object.locationType) {
				case 'categories':
					$item.find('.toggle-locations').addClass('hide');
			}

			count++;
			if (count == max) {
				$pages.append($page);
				count = 0;
				page++;
				$page = $('#pageTpl').clone();
				$page = $(untokenise($page.html(), { page: page }));
				$page.hide();
			}
		});

		if (count) {
			$pages.append($page);
		}

		// If the order has not changed don't fade
		if (sameHash()) {
			$('#items').empty().append($pages.children()).trigger('drawn.zpmaps');
		} else {
			$('#items')
				.fadeOut(
					PAGINATION_SPEED,
					() => {
						$('#items').empty().append($pages.children()).fadeIn(PAGINATION_SPEED).trigger('drawn.zpmaps');
					});
		}

		this.pagination();
	}

	locations.pagination = function (current = 1) {
		let max = getPageLength();
		let pageCount = Math.ceil(this.length / max);

		if (pageCount < 2) {
			return;
		}

		let $buttons = $('<ul>');
		let $link;
		let $content;
		let $ellipsis = $('<li>').addClass('ellipsis');

		$link = $('<li>').addClass('pagination-previous')
		if (current == 1) {
			$link.addClass('disabled').text('Previous');
		} else {
			$content = $('<a>').attr('href', '#').attr('aria-label', 'Previous page').attr('data-page', current - 1).text('Previous');
			$link.append($content);
		}
		$buttons.append($link);

		let ellipsis = false;

		let min = 10;           // Number after which we start using ellipsis
		let enders = 1;         // Number to always include at either end
		let surrounders = 5     // Number to always include around current

		for (var i = 1; i <= pageCount; i++) {
			if ((pageCount <= min) || (i < (enders + 1)) || (i > pageCount - enders) || (Math.abs(current - i) <= surrounders)) {
				$link = $('<li>');
				$content;
				if (i == current) {
					$link.text(' ' + i);
					$content = $('<span>').addClass('show-for-sr').text("You're on page");
					$link.addClass('current').prepend($content);
				} else {
					$content = $('<a>').attr('href', '#').attr('aria-label', 'Page ' + i).attr('data-page', i).text(i);
					$link.append($content);
				}
				$buttons.append($link);
				ellipsis = false;
			} else {
				if (ellipsis === false) {
					$buttons.append($ellipsis.clone());
				}
				ellipsis = true;
			}
		}

		$link = $('<li>').addClass('pagination-next')
		if (current == pageCount) {
			$link.addClass('disabled').text('Next');
		} else {
			$content = $('<a>').attr('href', '#').attr('aria-label', 'Next page').attr('data-page', current + 1).text('Next');
			$link.append($content);
		}
		$buttons.append($link);

		let $pagination = $(untokenise($('#paginationTpl').clone().html(), { pages: $buttons.html() }));

		$('#map-pagination').empty().append($pagination).data('page', current);
	}

	locations.sortItems = function () {
		locations.sort((a, b) => {
			let ad = a.sort == undefined ? 999999 : a.sort;
			let bd = b.sort == undefined ? 999999 : b.sort;
			if (ad == bd) {
				ad = a.distance == undefined ? 999999 : a.distance;
				bd = b.distance == undefined ? 999999 : b.distance;
				if (ad == bd) {
					ad = a.locationType == 'globals' ? -1 : 1;
					bd = b.locationType == 'globals' ? -1 : 1;
					if (ad == bd) {
						ad = a.locationType == 'sites' ? -1 : 1;
						bd = b.locationType == 'sites' ? -1 : 1;
						if (ad == bd) {
							ad = a.name.toLowerCase();
							bd = b.name.toLowerCase();
						}
					}
				}
			}
			return (ad < bd ? -1 : 1);
		});
	}

	locations.highlightSites = function (sites) {
		$.each(this, function (i, location) {
			let el = '#' + location.locationType + '_' + location.locationId;
			switch (location.locationType) {
				case 'sites':
					$.each(sites, function (s, id) {
						if (id == location.locationId) {
							$('#items').one('drawn.zpmaps', () => { $(el).trigger('active.zpmaps'); });
							return false;
						}
					})
					break;
				case 'groups':
					let found = false;
					$.each(location.sites, function (i, site) {
						$.each(sites, function (s, id) {
							if (id == site.siteId) {
								$('#items').one('drawn.zpmaps', () => { $(el).trigger('active.zpmaps'); });
								return false;
							}
						});
						if (found) {
							return false;
						}
					});
					break;
			}
		});
	}

	function checkForExactMatch(location, words) {
		let name = $.trim(location.name.toLowerCase()).replace(/[\/\s]+/, ' ');
		let parts = name.split(' ');
		if (parts.every((v, k) => v === words[k])) {
			location.sort = ['categories', 'locationsCollection'].includes(location.locationType) ? -1000 : -999;
			$('#items').one('drawn.zpmaps', () => {
				$('#items').find('li.item-parent').each(function () {
					let data = $(this).data();
					if (location.locationId == data.id && location.locationType == data.type) {
						$(this).click();
						return false;
					}
				});
			});
		}
	}

	function getSite(site, extend = {}) {
		let response = $.extend({}, site, extend);
		response.id = site.siteId ? site.siteId : site.locationId;
		delete response.siteId;
		delete response.locationId;
		let $info = $('#infoTpl').clone();
		response.content = untokenise($info.html(), response);
		return response;
	}

	function clearSearch() {
		$('#map-search').val('');
		if ($('#map-search').data('clear')) {
			return;
		}
		$('#map-search').data('clear', true);
	}

	function searchFromMap(latLng = false) {
		locations.setDistance(latLng === false ? false : latLng.lat(), latLng === false ? false : latLng.lng());
		locations.sortItems();
		locations.draw();
	}

	function getPageLength() {
		return 999999;
		let max = $('#page-length').val() || 10;
		return Number(max);
	}

	function sameHash() {
		let same;
		let current = locations.getHash();
		same = (current === hash);
		hash = current;
		return same;
	}

	function resetCategoryToggle() {
		$('.toggle-locations').addClass('hide');
		$('a.toggle-locations-link').each(function () {
			$(this).text(i18n.__('portal.map.locations.show'));
		});
	}

	function resetTariffs() {
		$('#map-tariffs').addClass('hide-panel').data('id', 0);
	}

	function setMap(el) {
		let result = $(el).data();

		mapPageMap.zp.closeAll();
		mapPageMap.zp.resetMarkers();

		let bounds;
		let sites = [];

		// Highlight and pan to all relevant markers on the map
		switch (result.type) {
			case 'sites':
				$.each(mapPageMap.zp.markers, function (i, marker) {
					let site = marker.zp.site;
					let found = false;
					$.each(site.locations, function (l, location) {
						if (location.id == result.id && location.type == 'sites') {
							idlecallback = $.noop;
							mapPageMap.panTo(marker.position);
							mapPageMap.setZoom(SINGLE_SITE_ZOOM);
							marker.zp.infoWindow.open({map:mapPageMap, shouldFocus: false}, marker);
							mapPageMap.zp.highlightMarker(marker);
							sites.push(location.id);
							found = true;
							return false;
						}
					});
					if (found) {
						return false;
					}
				});
				break;
			case 'groups':
				bounds = new google.maps.LatLngBounds();
				$.each(mapPageMap.zp.markers, function (i, marker) {
					let site = marker.zp.site;
					$.each(site.locations, function (l, location) {
						if (location.id == result.id && location.type == 'groups') {
							bounds.extend(marker.position);
							mapPageMap.zp.highlightMarker(marker);
							sites.push(site.id);
							return false;
						}
					}); location.locationType
				});
				idlecallback = $.noop;
				fitBounds(sites, bounds);
				break;
			case 'categories':
				let category;
				$.each(categories, function (c, cloc) {
					if (cloc.locationId == result.id) {
						category = cloc;
						return false;
					}
				});

				bounds = new google.maps.LatLngBounds();
				let hasGlobal = false;

				if (category.locations.every(cloc => cloc.type === 'dispensations')) {
					// do nothing
				} else {
					$.each(category.locations, function (c, cloc) {
						$.each(mapPageMap.zp.markers, function (i, marker) {
							let site = marker.zp.site;
							if (cloc.type == 'globals') {
								bounds.extend(marker.position);
								marker.zp.setIcon(MARKER_HIGHLIGHTED);
								hasGlobal = true;
							} else {
								$.each(site.locations, function (l, location) {
									if (location.id == cloc.id && location.type == cloc.type) {
										bounds.extend(marker.position);
										mapPageMap.zp.highlightMarker(marker);
										sites.push(site.id);
										return false;
									}
								});
							}
						});

						if (hasGlobal) {
							return false;
						}
					});

					idlecallback = $.noop;
					fitBounds(sites, bounds);
				}

				/*
				$.each(mapPageMap.zp.markers, function(i, marker) {
					let site = marker.zp.site;
					$.each(site.locations, function(l, location) {
						$.each(category.locations, function(c, cloc) {
							if (location.type == cloc.type && location.id == cloc.id) {
								// console.log(i, l, c, location.type, location.id, cloc);
							}
						});
					});
				});
				*/

				let $toggle = $(el).find('.toggle-locations')
				let closed = $toggle.hasClass('hide');
				resetCategoryToggle();
				if (closed) {
					$toggle.removeClass('hide');
					$(el).find('a.toggle-locations-link').text(i18n.__('portal.map.locations.hide'));
				} else {
					$toggle.addClass('hide');
					$(el).find('a.toggle-locations-link').text(i18n.__('portal.map.locations.show'));
				}

				break;
			case 'locationsCollection':
				let locationCollection;
				$.each(locationsCollection, function (c, cloc) {

					if (cloc.locationId == result.id) {
						locationCollection = cloc;
						return false;
					}
				});

				bounds = new google.maps.LatLngBounds();
				let hasGlobalLocationsCollection = false;

				$.each(locationCollection.locations, function (c, cloc) {

					$.each(mapPageMap.zp.markers, function (i, marker) {
						let site = marker.zp.site;
						if (cloc.type == 'globals') {
							bounds.extend(marker.position);
							marker.zp.setIcon(MARKER_HIGHLIGHTED);
							hasGlobalLocationsCollection = true;
						} else {
							$.each(site.locations, function (l, location) {
								if (location.id == cloc.id && location.type == cloc.type) {
									bounds.extend(marker.position);
									mapPageMap.zp.highlightMarker(marker);
									sites.push(site.id);
									return false;
								}
							});
						}
					});

					if (hasGlobalLocationsCollection) {
						return false;
					}
				});
				/*
				$.each(mapPageMap.zp.markers, function(i, marker) {
					let site = marker.zp.site;
					$.each(site.locations, function(l, location) {
						$.each(category.locations, function(c, cloc) {
							if (location.type == cloc.type && location.id == cloc.id) {
								// console.log(i, l, c, location.type, location.id, cloc);
							}
						});
					});
				});
				*/
				idlecallback = $.noop;
				fitBounds(sites, bounds);

				let $toggleLocationsCollection = $(el).find('.toggle-locations')
				let closedLocationsCollection = $toggleLocationsCollection.hasClass('hide');
				resetCategoryToggle();
				if (closedLocationsCollection) {
					$toggleLocationsCollection.removeClass('hide');
					$(el).find('a.toggle-locations-link').text(i18n.__('portal.map.locations.hide'));
				} else {
					$toggleLocationsCollection.addClass('hide');
					$(el).find('a.toggle-locations-link').text(i18n.__('portal.map.locations.show'));
				}

				break;
			case 'dispensations':
				// Do nothing
				break;
			default:
				bounds = new google.maps.LatLngBounds();
				$.each(mapPageMap.zp.markers, function (i, marker) {
					sites.push(marker.zp.site.id)
					bounds.extend(marker.position);
					marker.zp.setIcon(MARKER_HIGHLIGHTED);
				});
				//idlecallback = () => { searchFromMap(); }
				idlecallback = $.noop;
				fitBounds(sites, bounds);
				break;
		}

		// Highlight any location that contains a site that we're highlighting on the map
		// locations.highlightSites(sites);

		// Deselect all others and select this location
		$('li.item-parent').trigger('inactive.zpmaps');
		$(el).trigger('active.zpmaps');
	}

	function fitBounds(sites, bounds) {
		if (sites.length == 1) {
			$.each(mapPageMap.zp.markers, function (i, marker) {
				if (sites[0] == marker.zp.site.id) {
					mapPageMap.panTo(marker.position);
					mapPageMap.setZoom(SINGLE_SITE_ZOOM);
					marker.zp.infoWindow.open({map:mapPageMap, shouldFocus: false}, marker);
					return false;
				}
			});
			return false;
		} else {
			mapPageMap.fitBounds(bounds, MAP_PADDING);
			return true;
		}
	}

	/**
	 * Uses Google's Geocoding API to retrieve location details for a postcode.
	 *
	 * @param  string        postcode The postcode to submit.
	 * @param  function|null callback The function to call once we have a response.
	 * @return void
	 */
	function getBoundsForPostcode(postcode, callback = null)
	{
		geocoder = new google.maps.Geocoder();
		geocoder
			.geocode({ address: $.trim(postcode) })
			.then((response) => {
				if (typeof callback == 'function') {
					callback(response.results[0]);
				}
			})
			.catch((e) => {
				if (typeof callback == 'function') {
					callback(null);
				}
			});
	}

	/**
	 * Sets the dispensations Pin location from the result of a Geocoding API call.
	 *
	 * @see    getBoundsForPostcode
	 * @param  object|null result The result from the API, or null if unsuccessful.
	 * @return void
	 */
	function userLocationFromPostcode(result)
	{
		$('a.drop-pin').text('Drop Pin').prop('disabled', true);
		if (result) {
			dropPin(result.geometry.location);
		} else {
			const $postcode = $('#postcode-search').find('input[name="postcode"]')
			$('#postcode-search').foundation('addErrorClasses', $postcode);
			dropPin(mapPageMap.getCenter());
		}
	}

	/**
	 * Sets the map bounds from the result of a Geocoding API call.
	 *
	 * @see    getBoundsForPostcode
	 * @param  object|null result The result from the API, or null if unsuccessful.
	 * @return void
	 */
	function setBoundsFromResult(result)
	{
		if (result) {
			// This is how dropPin does it, so let's be consistent
			mapPageMap.panTo(result.geometry.location);
			mapPageMap.setZoom(15);
		} else {
			const $postcode = $('#postcode-search').find('input[name="postcode"]')
			$('#postcode-search').foundation('addErrorClasses', $postcode);
		}
	}

	function userLocationSuccess(position) {
		document.getElementById('use-current-location').removeAttribute('disabled');
		mapPageMap.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
	}

	function userLocationError(error) {
		switch (error.code) {
			case 1: // User declined

				break;
			case 2: // Service unavailable

				break;
			case 3: // Timeout

				break;
			default:

		}
		document.getElementById('use-current-location').removeAttribute('disabled');
		dropPin(mapPageMap.getCenter());
	}

	var pinMarker = null;
	var geocoder;

	function hideDropPin() {
		if (pinMarker == null) {
			return;
		}
		pinMarker.setMap(null);
		pinMarker = null;
	}

	function dropPin(latLng) {
		geocoder = new google.maps.Geocoder();

		const image = {
			url: MARKER_USER,
			size: new google.maps.Size(25, 44),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(13, 44),
		};

		pinMarker = new google.maps.Marker({
			position: latLng,
			map: mapPageMap,
			icon: image,
			draggable: true,
    		title: "Draggable Pin Marker",
		});

		pinMarker.zp = {};

		let $content = $('#dropPinInfoTpl').clone();
		$content.find('div.dropPinLocation').attr('id', 'dropPinLocation');

		pinMarker.zp.infoWindow = new google.maps.InfoWindow({
			ariaLabel: "Location Information Dialog Box",
		});
		pinMarker.zp.infoWindow.setContent($content.html());
		pinMarker.zp.infoWindow.setPosition(latLng);

		pinMarker.zIndex = google.maps.Marker.MAX_ZINDEX + 1;
		mapPageMap.panTo(pinMarker.position);

		const openPinMarker = (address, object) => {
			pinMarker.zp.infoWindow.open(pinMarker.map, pinMarker);
			pinMarker.zp.address = { text: address, object: object };
		};

		google.maps.event.addListener(pinMarker.zp.infoWindow, 'domready', function() {
			$('#dropPinLocation')
				.text(pinMarker.zp.address.text)
				.data('address', pinMarker.zp.address.object)
				.data('name', pinMarker.zp.address.text);
		});

		google.maps.event.addListener(pinMarker, 'click', function() {
			reverseGeocode(openPinMarker);
		});

		google.maps.event.addListener(pinMarker, 'dragstart', function(e) {
			pinMarker.zp.infoWindow.close();
		});

		google.maps.event.addListener(pinMarker, 'dragend', function(e) {
			reverseGeocode(openPinMarker);
		});

		google.maps.event.trigger(pinMarker, 'click');
	}

	function reverseGeocode(callback = null) {
		geocoder
			.geocode({ location: pinMarker.position })
			.then((response) => {
				const address = handleGeocode(response);
				if (typeof callback == 'function') {
					callback(address.address, address.object);
				}
			})
			.catch((e) => { if (console) console.log("Geocoder failed due to: " + e) });
	}

	createMap(
		'map',
		sites,
		{
			zoomControl: true,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				mapTypeIds: ["roadmap", "satellite", "hybrid"],
				position: google.maps.ControlPosition.TOP_LEFT,
			},
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false
		}
	).then(map => {
		window.mapPageMap = map;
		mapPageMap.zp.closeAll = function () {
			$.each(this.markers, function (k, marker) {
				marker.zp.infoWindow.close();
			})
		}

		mapPageMap.zp.resetMarkers = function (mkr = MARKER_DEFAULT) {
			$.each(this.markers, function (k, marker) {
				marker.zp.setIcon(mkr);
				marker.zIndex = null;
			})
		}

		mapPageMap.zp.highlightMarker = function (marker, mkr = MARKER_HIGHLIGHTED) {
			marker.zp.setIcon(mkr);
			marker.zIndex = google.maps.Marker.MAX_ZINDEX + 1;
		}

		mapPageMap.zp.setIcon = function(marker, mkr) {
			const icon = document.createElement('img');
			icon.src = mkr;
			marker.content = icon;
		}

		$.each(mapPageMap.zp.markers, function (i, marker) {
			google.maps.event.addListener(marker, 'click', function (e) {
				let site = marker.zp.site;
				mapPageMap.zp.closeAll();
				mapPageMap.zp.resetMarkers();
				mapPageMap.zp.highlightMarker(marker);
				mapPageMap.panTo(marker.position);
				marker.zp.infoWindow.open(mapPageMap, marker);

				let scrollTo = 999999;
				let $scroll;
				let scrollScore = 10;
				let scores = { sites: 0, groups: 1, categories: 2, globals: 3 };
				let tariffs = $('#map-tariffs').data();
				let closeTariffs = true;

				$('li.item-parent').trigger('inactive.zpmaps');
				$('li.item').each(function (i) {
					let $result = $(this);
					let result = $(this).data();

					let locationType = (typeof result.category == 'undefined') ? result.type : 'categories';

					$.each(site.locations, function (l, location) {
						if ((result.type == location.type) && (result.id == location.id)) {
							if ((locationType == 'sites')
								|| ((locationType == 'groups') && (scrollScore > 0))
								|| ((locationType == 'categories') && (scrollScore > 1))
								|| ((locationType == 'globals') && (scrollScore > 2))) {
								$scroll = $result;
								scrollScore = scores[locationType];
							}
							$result.trigger('active.zpmaps');
						}

						if ((tariffs.type == location.type) && (tariffs.id == location.id)) {
							closeTariffs = false;
						}
					});
				});

				if (closeTariffs) {
					resetTariffs();
				}
				$scroll[0].scrollIntoView({ block: "end", inline: "nearest", behaviour: "smooth" });
			});

			/* Looks useful but not being used
			$.each(marker.zp.site.locations, function(l, location) {
				locations[location.index].markers.push(marker);
			});
			*/
		});

		/**
		 * Initialisation
		 */
		let search = $('#map-search').val();
		$('#map-search').data('clear', !search.length);

		let lat = $('#search-lat').val();
		let lng = $('#search-lng').val();
		let bounds = $('#search-bounds').val();
		let place = $('#search-place').val();

		if (place.length) {
			search = place;
		}

		if (lat.length && lng.length) {
			locations.search(search, lat, lng, bounds);
		} else if (search) {
			locations.search(search, false);
		} else {
			locations.sortItems();
			locations.draw();

			if (sites.length == 0) {
				let bounds = getGoogleBounds();
				mapPageMap.fitBounds(bounds, MAP_PADDING);
			}
		}

		google.maps.event.addListener(mapPageMap, 'idle', function () {
			if (idle === false) {
				idle = true;
				/*
				if (mapPageMap.getZoom() > 13) {
					mapPageMap.setZoom(13);
				}
				*/
				return;
			}
			if (!idlecallback) {
				// searchFromMap(mapPageMap.getBounds().getCenter());
			} else {
				idlecallback.call();
				idlecallback = null;
			}
		});
	});

	let lastLocationItem; // Variable to store the last focused element

	function trapFocus(container) {
		const focusableElements = container.find(
			'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
		).filter(':visible');

		if (!focusableElements.length) {
			return; // No focusable elements, no need to trap focus
		}

		const firstFocusable = focusableElements.first();
		const lastFocusable = focusableElements.last();

		container.on('keydown', (e) => {
			if (e.key === 'Tab') {
				if (e.shiftKey) {
					// Shift + Tab: Move focus backward
					if (document.activeElement === firstFocusable[0]) {
						e.preventDefault();
						lastFocusable.focus();
					}
				} else {
					// Tab: Move focus forward
					if (document.activeElement === lastFocusable[0]) {
						e.preventDefault();
						firstFocusable.focus();
					}
				}
			}
		});
	}

	$('body')
		.on('active.zpmaps', 'li.item-parent', function () {
			$(this).addClass('selected');
		})
		.on('inactive.zpmaps', 'li.item-parent', function () {
			$(this).removeClass('selected');
		})
		.on('mouseover', 'li.item-child', function () {
			let result = $(this).data();
			$.each(mapPageMap.zp.markers, function (i, marker) {
				let site = marker.zp.site;
				$.each(site.locations, function (l, location) {
					if ((result.type == 'globals') || (location.id == result.id && location.type == result.type)) {
						marker.zp.previous = marker.zp.getIcon();
						marker.zp.setIcon(MARKER_HOVER);
						marker.zIndex = google.maps.Marker.MAX_ZINDEX + 1;
						return false;
					}
				});
			});
		})
		.on('mouseout', 'li.item-child', function () {
			let result = $(this).data();
			$.each(mapPageMap.zp.markers, function (i, marker) {
				let site = marker.zp.site;
				$.each(site.locations, function (l, location) {
					if ((result.type == 'globals') || (location.id == result.id && location.type == result.type)) {
						marker.zp.previous = marker.zp.getIcon();
						marker.zp.setIcon(MARKER_HIGHLIGHTED);
						marker.zIndex = null;
						return false;
					}
				});
			});
		})
		.on('drawn.zpmaps', '#items', function () {
			//console.log('drawn at', new Date()); // ######################################################################################################
		})
		.on('click tap', '#map-pagination a', function () {
			$('ul.page:visible').fadeOut(PAGINATION_SPEED, () => { $('#page-' + $(this).data('page')).fadeIn(PAGINATION_SPEED); });
			locations.pagination($(this).data('page'));
		})
		.on('click tap', 'a.map-search-clear', function () {
			clearSearch();
			searchFromMap();
			hideDropPin();
		})
		.on('keyup change', '#map-search', function (e) {
			if (e.which == 13) {
				if ($(this).data('set')) {
					if ($(this).data('bounds')) {
						mapPageMap.fitBounds($(this).data('bounds'));
					} else {
						mapPageMap.setCenter($(this).data('locations'));
						mapPageMap.setZoom(SINGLE_SITE_ZOOM);
					}
				}
			} else {
				$(this).data('set', false);
			}
			mapPageMap.zp.resetMarkers();
			hideDropPin();
		})
		.on('click tap', 'li.item-child', function (e) {
			e.stopPropagation(); // Don't let a click on a category location bubble through to the category item
		})
		.on('click tap keydown', 'li.item-parent', function (e) {

			if (e.type === 'keydown' && (e.key !== 'Enter')) {
				return; // Only proceed for Enter key
			}

			let result = $(this).data();

			if (result.type != 'categories') {
				resetCategoryToggle();
				if (false === $(e.target).hasClass('map-tariffs')) {
					let itemData = $(this).data();
					let current = $('#map-tariffs').data();
					if ((current.id != itemData.id) || (current.type != itemData.type) || (current.category != itemData.category)) {
						resetTariffs();
					}
				} else if (waitForTransition) {
					let hidden = $('#map-tariffs').hasClass('hide-panel');
					if (hidden) {
						return;
					}
				}
			}

			setMap($(this));
			hideDropPin();
		})
		.on('click tap', 'a.map-tariffs', function (e) {

			// Store last clicked element to restore focus later
			lastLocationItem = $(this).closest('a.map-tariffs');

			let $item = $(this).closest('.item');
			let itemData = $item.data();
			$item.addClass('active');

			let current = $('#map-tariffs').data();

			if ((current.id == itemData.id) && (current.type == itemData.type)) {
				resetTariffs();
				return;
			}

			$('#map-tariffs').data('type', itemData.type).data('id', itemData.id).data('category', itemData.category).data('wcag', itemData.wcag);

			$('#map-tariffs-title').text(i18n.__('labels.tariffs'));
			if (itemData.category) {
				$.each(categories, function (c, category) {
					if (itemData.category == category.id) {
						$('#map-tariffs-title').text(i18n.__('portal.map.tariffs.category', { category: category.name }));
						return false;
					}
				});
			}
			$('#map-tariffs-name').text($item.data('name'));
			let locationType = itemData.type.replace(/s$/, '');
			if (locationType == 'dispensation') {
				$('#map-tariffs-info').hide();
			} else {
				$('#map-tariffs-info').attr('href', `/app/locations/${locationType}/${itemData.id}`).show();
			}

			$.ajax({
				url: '/applicant/tariffs',
				method: 'post',
				data: itemData,
				dataType: 'html',
				success: (response) => {
					$('#map-tariffs').removeClass('hide-panel');
					$('#map-tariffs-list').html(response).foundation();

					// Set description from hidden div returned in the ajax response
					if ($('#location-description').length) {
						$('#map-tariffs-description').empty();
						let description = $('#location-description').html();
						if (description.length == 0) {
							$('#map-tariffs-description').hide();
						} else {
							$('#map-tariffs-description').append(description).show();
						}
					}

					// Focus the first focusable element in the panel
					setTimeout(() => {
						const mapTariffs = $('#map-tariffs');
						let firstFocusable = mapTariffs
							.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])')
							.filter(':visible')
							.first();
						if (firstFocusable.length) {
							firstFocusable.focus();
						}
						trapFocus(mapTariffs);

					}, 100); // Small delay to ensure the element is rendered and ready for focus

					if (itemData.category) {
						setMap($item);
					}
				}
			});
			hideDropPin();
		})
		.on('keydown', '#map-tariffs', function (e) {
			// Close the panel and restore focus when escape is pressed
			if (e.key === 'Escape') {
				resetTariffs();
				if (lastLocationItem) {
					lastLocationItem.focus();
				}
			}
		})
		.on('click tap', '#map-tariffs-close', function (e) {
			resetTariffs();
			if (lastLocationItem) {
				lastLocationItem.focus();
			}
		})
		.on('keydown', 'a.toggle-locations-link', function (e) {
			e.stopPropagation();
		})
		.on('click tap', 'a.drop-pin', function(e) {
			e.stopPropagation();
			if ($(this).hasClass('disabled')) {
				return false;
			}

			let tariffId = $(this).data('id');
			$('body').find('#dropPinSubmit').attr('data-id', tariffId);

			$(this).css('width', $(this).outerWidth() + 'px');

			$(this).text('Waiting').prop('disabled', true).addClass('disabled');

			dropPin(mapPageMap.getCenter());
			$(this).text('Drop Pin');

			resetTariffs();
		})
		.on('click tap', '#dropPinSubmit', function(e) {
			e.stopPropagation();
			e.preventDefault();

			if (false === $(this).hasClass('disabled')) {
				$(this).text('Processing').addClass('disabled');
				$.ajax({
					url: '/applicant/userDefined',
					method: 'post',
					data: {
						tariff: $(this).data('id'),
						lat: pinMarker.position.lat,
						lng: pinMarker.position.lng,
						address: $('#dropPinLocation').data('address'),
						name: $('#dropPinLocation').data('name'),
					},
					dataType: 'json',
					success: (response) => {
						if (response.error) {
							$('#dropPinSubmit').removeClass('disabled');
						} else {
							document.location = '/app/purchase/user/' + response.locationId + '/permit/' + response.tariff;
						}
					}
				});
			}

			return false;
		})
		.on('click tap', 'div.item-title a', function(e) {
			e.preventDefault();
			const $parent = $(this).closest('li.item-parent');
			if ($parent.data('type') == 'dispensations') {
				return;
			}
			hideDropPin();
		})
		.on('click tap', 'a.disabled', function (e) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		})
		.on('click tap', '#toggle-hidden-items', function(e) {
			const show = $(this).data('show');
			$(this).html($('#hiddenItemsTpl').children('span.' + (show ? 'h' : 's')).html())
			$(this).data('show', show ? 0 : 1);
			$('#hidden-items').toggle();
		})
		.on('formvalid.zf.abide', function (e, $form) {
			if ($form.attr('name') == 'postcode-search') {
				const postcode = $form.find('input[name="postcode"]').val();
				getBoundsForPostcode(postcode, setBoundsFromResult);
			}
		});

	$(document).on('on.zf.toggler', function (e) {
		// console.log('toggler on', e);
	});
	$(document).on('off.zf.toggler', function (e) {
		// console.log('toggler off', e);
	});

	if (waitForTransition) {
		$('#map-tariffs')[0].addEventListener('transitionend', (e) => {
			if (e.propertyName != 'width') {
				return;
			}
			let hidden = $('#map-tariffs').hasClass('hide-panel');
			if (!hidden) {
				let current = $('#map-tariffs').data();
				let $item = $('#' + current.wcag + '_' + current.type + '_' + current.id);
				setMap($item);
			}
		});
	}

	/*
		Google Autocomplete
	*/

	const input = document.getElementById("map-search");

	selectFirstPredictionOnEnter(input);
	autocompleteObserver();

	// 2024-05-03 The PlaceAutocompleteElement is only in Preview at this time, so sticking with old style
	// https://developers.google.com/maps/documentation/javascript/place-autocomplete-new
	const searchBox = new google.maps.places.Autocomplete(
		input,
		{
			bounds: getGoogleBounds(),
			strictBounds: false,
			fields: ["address_components", "geometry", "place_id"],
			componentRestrictions: { country: componentRestrictions.split(',') }
		}
	);

	searchBox.addListener("place_changed", () => {
		const place = searchBox.getPlace();

		if (!place.geometry || !place.geometry.location) {
			return;
		}

		if (place.geometry.viewport) {
			mapPageMap.fitBounds(place.geometry.viewport, MAP_PADDING);
			$('#map-search').data('bounds', place.geometry.viewport.toJSON());
		} else {
			mapPageMap.setCenter(place.geometry.location);
			mapPageMap.setZoom(SINGLE_SITE_ZOOM);
		}

		$('#map-search').data('location', place.geometry.location.toJSON());
		$('#map-search').data('set', true);

		locations.setDistance(place.geometry.location.lat(), place.geometry.location.lng());
		locations.sortItems();
		locations.draw();
	});

	$('#map').on("show_bounds", function () {
		const bounds = getGoogleBounds();
		const rectangle = new google.maps.Rectangle({
			strokeColor: "#FF0000",
			strokeOpacity: 0.3,
			strokeWeight: 1,
			fillColor: "#FF0000",
			fillOpacity: 0.15
		});
		rectangle.setBounds(bounds);
		rectangle.setMap(mapPageMap);
	});

	window.useCurrentLocation = function(target) {
		target.setAttribute('disabled', true);
		hideDropPin();
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(userLocationSuccess, userLocationError, { enableHighAccuracy: true });
		}
	}
}

window.showBounds = function () {
	$('#map').trigger('show_bounds');
}
